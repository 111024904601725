import React, { useCallback, MouseEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ROOT_FOLDER_NAME, PAGER_LIMITS } from '../consts';
import {
    gettUserPreferences,
    patchUserPreferences,
} from '../utils/UserPreferences';

import { FoldersAPI } from '../APIs/foldersApi';

import { Loading } from './atomic/effects';
import { TextField } from './atomic/forms';
import { FolderIcon, Icons } from './atomic/icons';
import { DialogBox, FileUploadBox } from './atomic/dialog';
import { IconButton } from './atomic/buttons';
import { ErrorDisplay } from './Errors';
import { FolderControl } from './folderview/FolderControl';
import { FolderDetail } from './folderview/FolderDetail';
import { FolderProjects } from './folderview/FolderProjects';
import { FolderViewTypes } from './folderview/consts';
import { FolderList } from './folderview/FolderList';
import { FolderThumbnails } from './folderview/FolderThumbnails';
import { CreateProjectDialog } from './CreateProjectDialog';
import { useFileDeleteDialog } from './folderview/DeleteFilesDialog';
import { EditFolderDialog } from './folderview/EditFolderDialog';

import { HttpClient } from '../utils/HttpUilts';
import { DialogProps, useDialog } from '../hooks/useDialog';
import { projectApi } from '../APIs/projectApi';
import { useMutationContextDialog } from '../hooks/useMutationDialog';
import { useAuth } from '../contexts/AuthContext';
import { FolderCollaboratorView } from './folderview/FolderCollaboratorView';
import { userApi } from '../APIs/userApi';
import { useToast } from './atomic/toast';
import { useGet } from '../hooks/useApi';
import { GroupSelector } from './user/GroupSelecter';
import { tenantApi } from '../APIs/tenants';

type AddFolderDialogProps = DialogProps<
    { folderId: string | null | undefined },
    { folderName: string; groupIds: string[] }
>;
const AddFolderDialog = ({
    options,
    onOK: onCommit,
    ...rest
}: AddFolderDialogProps) => {
    const { folderId } = options;
    const [folderName, setFolderName] = React.useState('');
    const [groupIds, setGroupIds] = React.useState<Array<string>>([]);
    const { isUser } = useAuth();
    const { data: tenantStatus } = useGet(tenantApi.get.getTenantStatus);
    const onOK = useCallback(
        () => onCommit({ folderName, groupIds }),
        [folderName, groupIds]
    );

    return (
        <DialogBox
            {...rest}
            okText="作成"
            onOK={onOK}
            disabled={
                tenantStatus?.data.permission &&
                isUser() &&
                groupIds.length == 0 &&
                folderId == null
            }
        >
            <TextField
                label="新規フォルダ名"
                autoFocus={true}
                _onChange={setFolderName}
                value={folderName}
                required={true}
            />
            {tenantStatus?.data.permission && !!!folderId && (
                <GroupSelector setGroupIds={setGroupIds} groupIds={groupIds} />
            )}
        </DialogBox>
    );
};
export interface FolderViewCheckModel {
    id: string;
    name: string;
    isFile: boolean;
}
export interface CopyFoldersModel {
    parentFolderId: string;
    folders: {
        id: string;
        name: string;
    }[];
}
let _copyFolders: CopyFoldersModel | null = null;

const defaultPage = (() => {
    const history = {};
    return {
        get: (folderId: string | undefined) => {
            if (!folderId) {
                folderId = 'root';
            }
            return folderId in history ? history[folderId] : 0;
        },
        set: (folderId: string | undefined, page: number) => {
            if (!folderId) {
                folderId = 'root';
            }
            history[folderId] = page;
        },
    };
})();
export const FolderView = () => {
    const { isAdmin, isSuperAdmin, isExternalUser } = useAuth();
    const { folderId } = useParams();
    const [uploadVisible, setUploadVisible] = React.useState<boolean>(false);
    const [uploadFolderVisible, setUploadFolderVisible] =
        React.useState<boolean>(false);
    const [checkList, setCheckList] = React.useState<FolderViewCheckModel[]>(
        []
    );
    const [copyFolders, setCopyFolders] =
        React.useState<CopyFoldersModel | null>(_copyFolders);

    const [limit, setLimit] = React.useState<number>(PAGER_LIMITS[0]);
    const [page, setPage] = React.useState<number>(defaultPage.get(folderId));
    const offset = React.useMemo(() => page * limit, [page, limit]);

    const userPrefs = gettUserPreferences();
    const [viewType, setViewType] = React.useState<FolderViewTypes>(
        userPrefs.folderViewType ?? FolderViewTypes.LIST
    );
    const toast = useToast();

    const onViewChange = (event: MouseEvent) => {
        const { value } = event.target;
        setCheckList([]);
        patchUserPreferences({ folderViewType: value });
        setViewType(value);
        setPage(defaultPage.get(folderId));
    };
    const [showEditFolderDialog, setShowEditFolderDialog] =
        React.useState<boolean>(false);

    const isAdminUser = isAdmin() || isSuperAdmin();

    const [showFolderProjects, setShowFolderProjects] =
        React.useState<boolean>(false);

    useGet(folderId ? projectApi.get.getVideoSliceStatus(folderId) : null, {
        refreshInterval: 3000,
    });

    const navigate = useNavigate();
    React.useEffect(() => {
        setPage(defaultPage.get(folderId));
        setCheckList([]);
    }, [folderId]);
    React.useEffect(() => {
        defaultPage.set(folderId, page);
    }, [page]);

    const { data: accessItemData } = useGet(
        folderId ? userApi.get.itemPermission(folderId) : null
    );

    React.useEffect(() => {
        _copyFolders = copyFolders ? { ...copyFolders } : null;
    }, [copyFolders]);

    React.useEffect(() => {
        if (accessItemData?.data.showAiResult) {
            setShowFolderProjects(true);
        }
    }, [accessItemData]);

    React.useEffect(() => {
        if (isAdminUser) {
            setShowFolderProjects(isAdminUser);
            return;
        }
    }, []);

    const addFolder = useDialog(
        AddFolderDialog,
        () => ({
            options: {
                folderId,
            },
            async onCommit({
                folderName,
                groupIds,
            }: {
                folderName: string;
                groupIds: Array<String>;
            }) {
                //console.log('addFolder', folderName);
                const parentFolderId = folderId;
                await FoldersAPI.post({
                    parentFolderId,
                    folderName,
                    groupIds,
                });
                mutate();
            },
            onError: 'フォルダの作成に失敗しました',
        }),
        [folderId]
    );
    const createProject = useMutationContextDialog(
        projectApi.mutate.createProject,
        CreateProjectDialog,
        {
            onSuccess: () => mutate(),
        }
    );
    const deleteFiles = useFileDeleteDialog(
        {
            onDelete() {
                mutate();
                setCheckList([]);
            },
        },
        []
    );

    const GET_ITEMS_URL = `/items/${folderId}`;
    const { data, error, isLoading, mutate } = HttpClient.useSWR(
        folderId
            ? `/item/${folderId}`
            : `/items?folder=1&file=1&limit=${limit}&offset=${offset}`,
        folderId
            ? `${GET_ITEMS_URL}?folder=1&file=1&limit=${limit}&offset=${offset}`
            : undefined
    );
    if (isLoading) return <Loading />;
    if (error) {
        console.warn('FolderView.error', error);
        return <ErrorDisplay error={error} />;
    }
    if (!data) {
        return (
            <ErrorDisplay error={Error('フォルダ情報の取得に失敗しました')} />
        );
    }

    const currentFolder = folderId
        ? {
              ...data[0].data.folder,
              founderFolderId: data[0].data.founderFolderId,
              items: data[1].data.map((item) => {
                  if (item.fileId) {
                      const file = item.file;
                      file.url = item.url;
                      file.thumbUrl = item.thumbUrl;
                      file.founderFolderId = item.founderFolderId;
                      file.mosaic = item.mosaic;
                      return file;
                  }
                  return item.folder;
              }),
              pagination: data[1].pagination,
              breadcrumbs: data[0].data.breadcrumbs,
          }
        : {
              pagination: data[0].pagination,
              breadcrumbs: null,
              folderName: ROOT_FOLDER_NAME,
              items: data[0].data.map((item) => {
                  return item.folder;
              }),
          };

    const folderUsers = data[0].data.folderUsers;
    const { pagination } = currentFolder;

    const onUploadDone = () => {
        mutate();
        setUploadVisible(false);
        setUploadFolderVisible(false);
    };

    const onClickDelete = () => {
        if (!checkList || checkList.length == 0) return;
        deleteFiles.show({ parentId: folderId!, files: checkList });
    };
    const onClickThumbDelete = (item) => {
        deleteFiles.show({ parentId: folderId!, files: [item] });
    };
    const onClickThumbCopy = (item) => {
        if (!folderId) {
            return;
        }
        setCopyFolders({
            parentFolderId: folderId,
            folders: [item],
        });
        toast('フォルダをコピーしました');
    };
    const onProjectRemoved = (_opts: { projectId: string }) => {
        mutate();
    };

    const onClickMapIcon = () => {
        navigate(`/app/f/${folderId}/map`);
    };

    const isShowCollaboratorAlert =
        !isExternalUser() && folderUsers?.length ? true : false;
    const onClickFolderUpload = () => {
        if (!folderId) {
            return;
        }
        setUploadFolderVisible(true);
    };

    const onFolderUploaded = () => {
        mutate();
    };

    const _viewType = folderId ? viewType : FolderViewTypes.LIST;

    // 管理者ユーザーでRootフォルダの場合のみ表示する
    const isCollaboratorView =
        !isExternalUser() && currentFolder && currentFolder.breadcrumbs;

    const isCollaboratorWrite = isAdmin() || isSuperAdmin();

    if (folderId && !accessItemData) {
        return <></>;
    }

    const controls = (
        <FolderControl
            viewType={_viewType}
            currentFolder={currentFolder}
            onViewChange={onViewChange}
            onClickAddFolder={addFolder.show}
            onClickUpload={() => setUploadVisible(true)}
            onClickDelete={onClickDelete}
            onClickAddProject={() =>
                createProject.show({ folderId: folderId! })
            }
            onClickMapIcon={onClickMapIcon}
            onClickFolderUpload={onClickFolderUpload}
            onFolderUploaded={onFolderUploaded}
            checkList={checkList}
            hasCollaborator={isShowCollaboratorAlert}
            copyFolders={copyFolders}
            setCopyFolders={setCopyFolders}
        />
    );

    return (
        <div className="app-main folder-view">
            {controls}
            <h2 className="current-folder-name">
                <FolderIcon className="folder-icon" />
                <span className="name">{currentFolder.folderName}</span>
                {accessItemData?.data.write == 1 && (
                    <IconButton
                        icon={Icons.Edit}
                        onClick={() => setShowEditFolderDialog(true)}
                        className="edit-folder-icon no-decoration"
                    >
                        フォルダの編集
                    </IconButton>
                )}
            </h2>
            {folderId && (
                <>
                    <FolderDetail
                        folder={currentFolder}
                        defaultVisible={false}
                        isAccess={accessItemData?.data}
                    />
                    {showFolderProjects && (
                        <FolderProjects
                            folder={currentFolder}
                            defaultVisible={true}
                            onRemoved={onProjectRemoved}
                        />
                    )}

                    {isCollaboratorView && (
                        <FolderCollaboratorView
                            users={folderUsers}
                            folder={currentFolder}
                            defaultVisible={false}
                            mutate={mutate}
                            isWrite={isCollaboratorWrite}
                        />
                    )}
                </>
            )}
            {!folderId && <div className="no-detail-spacer" />}

            {_viewType === FolderViewTypes.LIST && (
                <FolderList
                    currentFolder={currentFolder}
                    checkList={checkList}
                    setCheckList={setCheckList}
                    setPage={setPage}
                    setLimit={setLimit}
                    pagination={pagination}
                    isAccess={accessItemData?.data}
                />
            )}
            {_viewType === FolderViewTypes.THUMB && (
                <FolderThumbnails
                    currentFolder={currentFolder}
                    pagination={pagination}
                    setPage={setPage}
                    setLimit={setLimit}
                    onClickDelete={onClickThumbDelete}
                    onClickCopy={onClickThumbCopy}
                    isAccess={accessItemData?.data}
                />
            )}

            {controls}

            {showEditFolderDialog && (
                <EditFolderDialog
                    folderId={currentFolder.folderId}
                    parentFolderId={currentFolder.parentFolderId}
                    name={currentFolder.folderName}
                    onClose={(data) => {
                        if (data) {
                            mutate();
                        }
                        setShowEditFolderDialog(false);
                    }}
                />
            )}
            {addFolder.dialog}

            {uploadVisible && folderId && (
                <FileUploadBox
                    folderId={folderId}
                    onCancel={() => setUploadVisible(false)}
                    onDone={onUploadDone}
                    isSwhoAlert={isShowCollaboratorAlert}
                />
            )}
            {uploadFolderVisible && folderId && (
                <FileUploadBox
                    folderId={folderId}
                    onCancel={() => setUploadFolderVisible(false)}
                    onDone={onUploadDone}
                    isSwhoAlert={isShowCollaboratorAlert}
                    uploadFolder={true}
                />
            )}

            {deleteFiles.dialog}
            {createProject.dialog}
        </div>
    );
};
export default FolderView;
