import React, { useState } from 'react';
import { FC } from 'react';
import { Button } from '../../atomic/buttons';
import { getAsync } from '../../../hooks/useApi';
import { projectApi } from '../../../APIs/projectApi';
import { TaskSummaryModel } from '../../../models/TaskModel';
import { downloadText } from '../../../utils/download';
import { useTenant } from '../../../contexts/TenantContext';
import { useAuth } from '../../../contexts/AuthContext';
import {
    difMinutes,
    getYearMonthString,
    reformat,
} from '../../../utils/DateUtils';

type Prop = {
    tenant: any;
};

const getTerm = (month: number) => {
    const now = new Date();
    const nowYear = now.getFullYear();
    const nowMonth = now.getMonth();
    const startDate = new Date(nowYear, nowMonth - month, 1);
    const endDate = new Date(nowYear, nowMonth + 1 - month, 1);

    return {
        start: startDate.toLocaleString(),
        end: endDate.toLocaleString(),
    };
};

export const DownloadSummaries: FC<Prop> = (props) => {
    const GET_TASK_LIMIT = 300;
    const [loading, setLoading] = useState<boolean>(false);
    const { getTenantUser } = useTenant();
    const { isSuperAdmin } = useAuth();

    const download = async (tenant: any, month: number) => {
        setLoading(true);
        const { start, end } = getTerm(month);
        const getTask = async (offset: number, prev: TaskSummaryModel[]) => {
            const data = await getAsync(
                projectApi.get.summary(
                    start,
                    end,
                    offset,
                    GET_TASK_LIMIT,
                    tenant.tenantId
                )
            );
            if (data.data.length) {
                const add = [...prev, ...data.data];
                if (data.data.length >= GET_TASK_LIMIT) {
                    return await getTask(data.pagination.nextOffset, add);
                }
                return add;
            }
            return prev;
        };
        const tasks = await getTask(0, []);

        const csvData = tasks.map((r) => {
            const user = getTenantUser(r.createdUserId);
            const userName = user
                ? `${user.familyName} ${user.givenName}`
                : 'unknown';
            const email = user ? `${user.email}` : 'unknown';
            const tenantName = () => {
                return r.tenantName;
            };

            const row = [
                r.projectName,
                r.aiAnalysisTypeNameJa,
                r.folderName,
                r.status,
                reformat(r.createdAt, 'yyyy/MM/dd HH:mm'),
                reformat(r.updatedAt, 'yyyy/MM/dd HH:mm'),
                difMinutes(r.createdAt, r.updatedAt),
                r.aiRunTime ? Math.ceil(r.aiRunTime / 1000 / 60) : '',
                r.image,
                userName,
                email,
            ];
            if (isSuperAdmin()) {
                row.unshift(tenantName());
            }
            return row;
        });
        const header = [
            'プロジェクト名',
            'AI名',
            'フォルダ名',
            'ステータス',
            '作成日時',
            '更新日時',
            '実効時間(分)',
            '推論時間(分)',
            '推論画像数',
            '実行ユーザー名',
            'メールアドレス',
        ];
        if (isSuperAdmin()) {
            header.unshift('テナント名');
        }
        csvData.unshift(header);
        const csvContent =
            '\ufeff' +
            csvData
                .map((row) => {
                    return row
                        .map((col) => {
                            return (
                                '"' +
                                (typeof col == 'string'
                                    ? col.replace(/\"/g, '""')
                                    : col) +
                                '"'
                            );
                        })
                        .join(',');
                })
                .join('\n');

        downloadText(
            `${tenant.name}_${getYearMonthString(month)}_tasks_log.csv`,
            csvContent,
            'text/csv'
        );
        setLoading(false);
    };

    return (
        <div className="downloadList">
            {[0, 1, 2].map((m) => (
                <Button
                    onClick={() => download(props.tenant, m)}
                    disabled={loading}
                    key={m}
                >
                    {getYearMonthString(m)}
                </Button>
            ))}
        </div>
    );
};
