import { VideoToImageMode } from './AiProcessModel';
import {
    FileItemFile,
    FileItemModel,
    FrameItemModel,
    ItemModel,
} from './ItemModel';
export interface FileModelMetadata {
    location?: {
        latitude: number;
        longitude: number;
    };
}
export interface FileModel {
    fileId: string;
    folderId: string;
    fileName: string;
    mimeType: string;
    createdUserId: string;
    updatedUserId: string;
    createdAt: string;
    updatedAt: string;
    url: string;
    sourceId?: string;
    videoTimeSeconds?: number;
    frames?: FileModel[];
    thumbUrl: string;
    metadata: FileModelMetadata;
}

export function formatName(file: FileItemModel | FrameItemModel): string {
    const type = isPhoto(file) ? '📸' : '🎥';
    return `${type} ${file.name}`;
}

// TODO: not implemented in server
export interface FileSource {
    fileId: string;
    time: number;
}

const photoMimeTypes = new Set([
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'image/png',
    'image/webp',
    'image/jpeg2000',
]);

const videoMimeTypes = new Set([
    'video/mp3',
    'video/mp4',
    'video/3gpp',
    'video/quicktime',
    'video/webm',
]);

export function isPhoto(file?: ItemModel | FileItemFile) {
    const mimeType = file?.mimeType;
    if (!mimeType) return false;
    return photoMimeTypes.has(mimeType.toLowerCase());
}

export function isVideo(file?: ItemModel | FileItemFile) {
    const mimeType = file?.mimeType;
    if (!mimeType) return false;
    return videoMimeTypes.has(mimeType.toLowerCase());
}
export interface FileUpload {
    fileId: string;
    fileName: string;
    mimeType: string;
}

export interface CanvasObject {
    canvas: HTMLCanvasElement;
    fileName: string;
    mimeType: string;
    imageCompression?: number;
}

export type MoveSliceOptions = {
    slice: number;
    mode: VideoToImageMode;
    minDistanceInM?: number;
    removeVideo: boolean;
} | null;

export interface FileUploadRequest<Data extends File | CanvasObject> {
    file: Data;
    folderId: string;
    sourceId?: string;
    videoTimeSeconds?: number;
    sliceOptions?: MoveSliceOptions;
}

// https://axios-http.com/docs/req_config
export type Data =
    | ArrayBuffer
    | ArrayBufferView
    | URLSearchParams
    | FormData
    | File
    | Blob;
