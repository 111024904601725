import { parseISO, format as _format, isValid } from 'date-fns';
import { ja } from 'date-fns/locale';

export const DEFAULT_FORMAT = 'yyyy/MM/dd(E) HH:mm';

type attrs = Parameters<typeof _format>;

export type DateInput = attrs[0];
export type FormatPattern = attrs[1];
export type FormatOptions = attrs[2];

export const format = (
    d: DateInput,
    ptn: FormatPattern = DEFAULT_FORMAT,
    opt: FormatOptions = { locale: ja }
) => {
    return _format(new Date(d), ptn, opt);
};

export const reformat = (
    str: string,
    ptn?: FormatPattern,
    opt?: FormatOptions
) => {
    const d = parseISO(str);
    return isValid(d) ? format(d, ptn, opt) : 'invalid date';
};

export const getTime = (str: string) => parseISO(str).getTime();

export const diffTime = (startTime: string, endTime: string) => {
    return getTime(endTime) - getTime(startTime);
};

export const difMinutes = (start: string, end: string) => {
    return Math.ceil(diffTime(start, end) / 1000 / 60);
};

export const getYearMonthString = (month: number) => {
    const now = new Date();

    if (month < 0) {
        return '';
    }

    const t = new Date(now.getFullYear(), now.getMonth() - month, 1);

    return `${t.getFullYear()}年${t.getMonth() + 1}月`;
};

export { parseISO };
