export interface StartAiProcessRequest {
    fileIds: string[];
    projectId: string;
}

type SliceSegments = Array<[start: number, end: number | null]>;

export enum VideoToImageMode {
    MS = 'ms',
    KEYFRAMES = 'keyframes',
}

export interface BasicFramesConfig {
    slice?: number;
    minDistanceInM?: number;
    mode?: VideoToImageMode;
}

export interface FileFramesConfig extends BasicFramesConfig {
    start?: number;
    duration?: number;
    end?: number;
    segments?: SliceSegments;
}

export interface StartVideoSliceProcess {
    frames?: BasicFramesConfig;
    folderId: string;
    files: Array<{
        fileId: string;
        frames?: FileFramesConfig;
    }>;
    removeVideo?: 1 | 0;
}

export interface StartFolderAiProcessRequest {
    frames?: BasicFramesConfig;
    projectId: string;
    removeVideo?: 1 | 0;
    resume?: 1 | 0;
}
