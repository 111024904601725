import React from 'react';
import classNames from 'classnames';

import { reformat } from '../../utils/DateUtils';

import { FolderIcon, TagIcon } from '../atomic/icons';
import { DialogBox } from '../atomic/dialog';
import { TagList } from '../atomic/tags';
import { SlideSwitch } from '../atomic/switches';
import { Loading } from '../atomic/effects';
import { UserName } from '../atomic/text';

import { TagInfoSection } from './TagInfo';
import {
    TenantTagModel,
    TagTypeModel,
    TagDetailModel,
} from '../../models/TagModel';
import {
    EditTagDialog,
    FolderDetailTagModel,
    FolderDetailTagTypeModel,
} from './EditTagDialog';
import {
    storeTagDetail,
    addTag,
    getTenantTags,
    getTagTypes,
    addTagDetail,
    deleteTag,
} from '../../APIs/tagApi';

import { ErrorContext } from '../../contexts';
import { useTenant } from '../../contexts/TenantContext';
import { useAuth } from '../../contexts/AuthContext';

type DeleteTagDialogData = {
    tagId: string;
    tagName: string;
};

export const FolderDetail = (props) => {
    const { folder, defaultVisible = false, isAccess } = props;
    const { getTenantUser } = useTenant();

    const [visible, setVisible] = React.useState(defaultVisible);
    const [editTagTarget, setEditTagTarget] =
        React.useState<FolderDetailTagModel | null>(null);
    const { createdAt, createdUserId, updatedAt, updatedUserId } = folder;
    const [showAddTagDialog, setShowAddTagDialog] =
        React.useState<boolean>(false);
    const [tags, setTags] = React.useState<Array<FolderDetailTagModel>>(
        folder.tags
    );
    const [loading, setLoading] = React.useState<boolean>(false);
    const [tenantTags, setTenantTags] = React.useState<Array<TenantTagModel>>(
        []
    );
    const [leftTenantTags, setLeftTenantTags] = React.useState<
        Array<TenantTagModel>
    >([]);
    const [tagTypes, setTagTypes] = React.useState<Array<TagTypeModel>>([]);
    const { setErrors } = React.useContext(ErrorContext);
    const [deleteTagDialogData, setDeleteTagDialogData] =
        React.useState<DeleteTagDialogData | null>(null);
    const { isExternalUser } = useAuth();

    React.useEffect(() => {
        getTenantTags()
            .then((resp) => {
                if (resp.success && resp.data) {
                    setTenantTags(resp.data);
                } else {
                    setErrors((errors) => [
                        ...errors,
                        resp.message ||
                            'テナントで利用可能な属性値の取得に失敗しました',
                    ]);
                }
            })
            .catch((err) => {
                console.warn(err);
                setErrors((errors) => [
                    ...errors,
                    err.message ||
                        'テナントで利用可能な属性値の取得に失敗しました',
                ]);
            });
        getTagTypes()
            .then((resp) => {
                console.log(resp);
                if (resp.success && resp.data) {
                    setTagTypes(resp.data);
                } else {
                    setErrors((errors) => [
                        ...errors,
                        resp.message ||
                            'テナントで利用可能な属性値の取得に失敗しました',
                    ]);
                }
            })
            .catch((err) => {
                console.warn(err);
                setErrors((errors) => [
                    ...errors,
                    err.message ||
                        'テナントで利用可能な属性値の取得に失敗しました',
                ]);
            });
    }, []);

    React.useEffect(() => {
        setLeftTenantTags((prevState) => {
            if (!tags) {
                return;
            }
            const nextState = JSON.parse(JSON.stringify(tenantTags));
            return nextState.filter((tenantTag: TenantTagModel) => {
                return !tags.find(
                    (item) => item.tenantTagId == tenantTag.tenantTagId
                );
            });
        });
    }, [tenantTags, tags]);

    const onEditTagClick = React.useCallback(
        (tag: FolderDetailTagModel) => {
            setEditTagTarget(tag);
        },
        [folder.folderId]
    );

    React.useEffect(() => {
        setTags(folder.tags);
    }, [folder.tags]);

    const onDeleteTagClick = React.useCallback((tag: FolderDetailTagModel) => {
        setDeleteTagDialogData({
            tagId: tag.tagId,
            tagName: tag.tagName,
        });
    }, []);

    const onDeleteTagDilogOK = React.useCallback(
        (dialog: DialogBox) => {
            if (deleteTagDialogData) {
                setLoading(true);
                const { tagId } = deleteTagDialogData;
                deleteTag(tagId)
                    .then((resp) => {
                        console.log(resp);
                        if (resp.success) {
                            setTags((prevState) => {
                                const nextState = JSON.parse(
                                    JSON.stringify(prevState)
                                );
                                return nextState.filter(
                                    (item) => item.tagId != tagId
                                );
                            });
                            setDeleteTagDialogData(null);
                        } else {
                            dialog.addError(
                                resp.message || '属性値の削除に失敗しました'
                            );
                        }
                    })
                    .catch((err) => {
                        console.warn(err);
                        dialog.addError(
                            err.message || '属性値の削除に失敗しました'
                        );
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        [deleteTagDialogData]
    );

    const onDeleteTagDialogCancel = React.useCallback(() => {
        setDeleteTagDialogData(null);
    }, []);

    const onEditTagDialogCancel = React.useCallback(() => {
        setEditTagTarget(null);
    }, []);

    const onEditTagDialogOK = React.useCallback(
        (
            dialog: DialogBox,
            values: Array<FolderDetailTagTypeModel>,
            tagId: string,
            tenantTagId: string
        ) => {
            // 既存の属性の修正
            let counter = values.length;
            let errorMessage = '';

            const updateTable = (tagDetail: TagDetailModel) => {
                setTags((prevState) => {
                    const nextState = JSON.parse(JSON.stringify(prevState));
                    const tag: FolderDetailTagModel | null = nextState.find(
                        (item: FolderDetailTagModel) => item.tagId == tagId
                    );
                    if (!tag) {
                        return prevState;
                    }
                    const detail = tag.values.find(
                        (item: FolderDetailTagTypeModel) =>
                            item.tagDetailId == tagDetail.tagDetailId
                    );
                    if (!detail) {
                        const tagType = tagTypes.find(
                            (item) => item.tagTypeId == tagDetail.tagTypeId
                        );
                        tag.values.push({
                            ...tagDetail,
                            tagType: tagType?.type,
                            tagTypeName: tagType?.name ?? '',
                            sortOrder: tagType?.sortOrder ?? 0,
                        });
                        return nextState;
                    }
                    detail.value = tagDetail.value;
                    return nextState;
                });
            };
            const onFinally = () => {
                counter--;
                if (!counter) {
                    setLoading(false);
                    if (errorMessage) {
                        dialog.addError(errorMessage);
                    } else {
                        setEditTagTarget(null);
                    }
                }
            };
            setLoading(true);
            values.forEach((value: FolderDetailTagTypeModel) => {
                if (value.tagDetailId) {
                    storeTagDetail(value.tagDetailId, {
                        tagDetailId: value.tagDetailId,
                        value: value.value,
                    })
                        .then((resp) => {
                            console.log(resp);
                            if (resp.success && resp.data) {
                                updateTable(resp.data);
                            } else {
                                errorMessage =
                                    resp.message ||
                                    '属性値の更新に失敗しました';
                            }
                        })
                        .catch((err) => {
                            console.warn(err);
                            errorMessage =
                                err.message || '属性値の更新に失敗しました';
                        })
                        .finally(onFinally);
                } else {
                    addTagDetail({
                        tagId: tagId,
                        tagTypeId: value.tagTypeId,
                        value: value.value || ' ',
                    })
                        .then((resp) => {
                            console.log(resp);
                            if (resp.success && resp.data) {
                                updateTable(resp.data);
                            } else {
                                errorMessage =
                                    resp.message ||
                                    '属性値の追加に失敗しました';
                            }
                        })
                        .catch((err) => {
                            console.warn(err);
                            errorMessage =
                                err.message || '属性値の追加に失敗しました';
                        })
                        .finally(onFinally);
                }
            });
        },
        []
    );

    const onAddTagLinkClick = React.useCallback(() => {
        setShowAddTagDialog(true);
    }, []);

    const onAddTagDialogOK = React.useCallback(
        (
            dialog: DialogBox,
            values: Array<FolderDetailTagTypeModel>,
            tagId: string,
            tenantTagId: string
        ) => {
            if (!tenantTagId) {
                return false;
            }
            setLoading(true);
            addTag({
                folderId: folder.folderId,
                tenantTagId: tenantTagId,
            })
                .then((resp) => {
                    console.log(resp);
                    if (resp.success && resp.data) {
                        const { tagId, isOverWrite } = resp.data;
                        let counter = values.length;
                        let errorMessage: string = '';

                        const newTag: FolderDetailTagModel = {
                            tenantTagId,
                            tagId,
                            isOverWrite,
                            tagName:
                                tenantTags.find(
                                    (item) => item.tenantTagId == tenantTagId
                                )?.name ?? '',
                            values: [],
                        };
                        values.forEach((item: FolderDetailTagTypeModel) => {
                            addTagDetail({
                                tagId: tagId,
                                tagTypeId: item.tagTypeId,
                                value: item.value || ' ',
                            })
                                .then((resp) => {
                                    console.log(resp);
                                    if (resp.success && resp.data) {
                                        const { tagTypeId } = resp.data;
                                        const tagType = tagTypes.find(
                                            (item) =>
                                                item.tagTypeId == tagTypeId
                                        );
                                        newTag.values.push({
                                            ...resp.data,
                                            tagType: tagType?.type,
                                            tagTypeName: tagType?.name ?? '',
                                            sortOrder: tagType?.sortOrder ?? 0,
                                        });
                                    } else {
                                        errorMessage =
                                            resp.message ||
                                            '属性値の追加に失敗しました';
                                    }
                                })
                                .catch((err) => {
                                    console.warn(err);
                                    errorMessage =
                                        err.message ||
                                        '属性値の追加に失敗しました';
                                })
                                .finally(() => {
                                    counter--;
                                    if (!counter) {
                                        setTags((prevState) => {
                                            const nextState = JSON.parse(
                                                JSON.stringify(prevState)
                                            );
                                            nextState.push(newTag);
                                            return nextState;
                                        });
                                        setLoading(false);
                                        if (errorMessage) {
                                            // エラーが発生している場合はタグを消す
                                            deleteTag(tagId);
                                            dialog.addError(errorMessage);
                                        } else {
                                            setShowAddTagDialog(false);
                                        }
                                    }
                                });
                        });
                    } else {
                        dialog.addError(
                            resp.message || '属性値の追加に失敗しました'
                        );
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(
                        err.message || '属性値の追加に失敗しました'
                    );
                    setLoading(false);
                });
        },
        [tenantTags, folder]
    );
    const onAddTagDialogCancel = React.useCallback(() => {
        setShowAddTagDialog(false);
    }, []);

    visible && console.log('FolderDetail', folder);
    return (
        <>
            <div
                className={classNames('details-outer folder-details', {
                    visible,
                })}
            >
                <div className={classNames('detail-head', { visible })}>
                    <TagList tags={tags} />
                    <div className="controls">
                        <SlideSwitch
                            name="detailVisibility"
                            defaultValue={visible}
                            onChange={({ value }) => setVisible(value)}
                            text="フォルダ詳細表示"
                            textPosition="left"
                        />
                    </div>
                </div>
                {visible && (
                    <div className="details">
                        <section>
                            <label className="title">
                                <FolderIcon solid />
                                フォルダ情報
                            </label>
                            <ul className="info-list">
                                <li className="created">
                                    <label>作成</label>
                                    <span className="datetime">
                                        {reformat(createdAt)}
                                    </span>
                                    <UserName
                                        user={getTenantUser(createdUserId)}
                                    />
                                </li>
                                <li className="updated">
                                    <label>更新</label>
                                    <span className="datetime">
                                        {reformat(updatedAt)}
                                    </span>
                                    <UserName
                                        user={getTenantUser(updatedUserId)}
                                    />
                                </li>
                                {folder.appId && (
                                    <li className="appId">
                                        <label>アプリID</label>
                                        <span className="text">
                                            {folder.appId}
                                        </span>
                                    </li>
                                )}
                                {folder.retentionPeriodAt && (
                                    <li className="folderLifeTime">
                                        <label>有効期限</label>
                                        <span className="datetime">
                                            {reformat(folder.retentionPeriodAt)}
                                        </span>
                                    </li>
                                )}
                            </ul>
                        </section>
                        {isAccess.showTags === 1 && (
                            <>
                                {tags.map(
                                    (tag) =>
                                        tenantTags.find(
                                            ({ tenantTagId }) =>
                                                tenantTagId == tag.tenantTagId
                                        ) != null && (
                                            <TagInfoSection
                                                tag={tag}
                                                tagTypes={tagTypes}
                                                key={tag.tagId}
                                                isWrite={
                                                    isAccess.write === 1
                                                        ? true
                                                        : false
                                                }
                                                table
                                                onEditClick={onEditTagClick}
                                                onDeleteClick={onDeleteTagClick}
                                            />
                                        )
                                )}
                                {leftTenantTags.length > 0 &&
                                    isAccess.write === 1 &&
                                    !isExternalUser() && (
                                        <section className={'tag-info-section'}>
                                            <div className="title right">
                                                <div
                                                    className="add-new-tag"
                                                    onClick={onAddTagLinkClick}
                                                >
                                                    <span className="plus">
                                                        +
                                                    </span>
                                                    <TagIcon />
                                                    新規タグ作成
                                                </div>
                                            </div>
                                        </section>
                                    )}
                            </>
                        )}
                    </div>
                )}
                {editTagTarget && (
                    <EditTagDialog
                        tag={editTagTarget}
                        onOK={onEditTagDialogOK}
                        onCancel={onEditTagDialogCancel}
                        folderId={folder.folderId}
                        tagTypes={tagTypes}
                    />
                )}
                {showAddTagDialog && (
                    <EditTagDialog
                        tag={null}
                        onOK={onAddTagDialogOK}
                        onCancel={onAddTagDialogCancel}
                        folderId={folder.folderId}
                        selectableTags={leftTenantTags}
                        tagTypes={tagTypes}
                    />
                )}
                {deleteTagDialogData && (
                    <DialogBox
                        title="属性値の削除"
                        onOK={onDeleteTagDilogOK}
                        onCancel={onDeleteTagDialogCancel}
                    >
                        {deleteTagDialogData.tagName} を削除しても良いですか？
                    </DialogBox>
                )}
            </div>
            {loading && <Loading />}
        </>
    );
};
