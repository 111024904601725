import React, { FC } from 'react';
import { useGet } from '../../hooks/useApi';
import { userApi } from '../../APIs/userApi';
import { useAuth } from '../../contexts/AuthContext';
import { CheckBox } from '../atomic/switches';
import { Loading } from '../atomic/effects';
import { useTenant } from '../../contexts/TenantContext';
import { tenantApi } from '../../APIs/tenants';

type Prop = {
    setGroupIds;
    groupIds: Array<String>;
};
export const GroupSelector: FC<Prop> = ({ setGroupIds, groupIds }) => {
    const groups = useGet(userApi.get.groupsForUser());
    const { isUser } = useAuth();

    const { data: tenantStatus } = useGet(tenantApi.get.getTenantStatus);

    const selected = (groupId: String) => {
        return groupIds.some((id) => id == groupId);
    };
    const onChange = (groupId: String) => {
        const _groupIds = groupIds.some((id) => id == groupId)
            ? groupIds.filter((id) => id !== groupId)
            : [...groupIds, groupId];

        setGroupIds(_groupIds);
    };

    if (!isUser() || !tenantStatus?.data.permission) {
        return <></>;
    }

    if (!groups.data) {
        return <Loading />;
    }
    if (groups.data.data.length == 0) {
        return (
            <>
                <p className="color-error-text">グループに所属していません</p>
            </>
        );
    }

    // グループが1つだけの場合は選択肢を出さない
    if (groupIds.length == 0 && groups.data.data.length == 1) {
        onChange(groups.data.data[0].groupId);
    }
    if (groups.data.data.length == 1 && groupIds.length == 1) {
        return <></>;
    }

    return (
        <>
            <p>グループ選択</p>
            {groupIds.length === 0 && (
                <p className="color-error-text">グループを選択してください</p>
            )}
            <ul className="groups">
                {!!groups.data &&
                    groups.data.data.map((group) => {
                        return (
                            <li key={group.groupId}>
                                <CheckBox
                                    label={group.name}
                                    name="aspect-enabled"
                                    checked={selected(group.groupId)}
                                    _onChange={() => onChange(group.groupId)}
                                />
                            </li>
                        );
                    })}
            </ul>
        </>
    );
};
