import { IComputedValue } from 'mobx';
import { LocalCodec, createLocalBox } from '../../utils/LocalStrageUtils';
import { VideoToImageMode } from '../../models/AiProcessModel';

const LOCAL_KEY_PREFIX = 'REEL_ASPECT';

function coerceInt(input: any, fallback: number = 0) {
    if (input === null || input === undefined) {
        return fallback;
    }
    const val = parseInt(input, 10);
    return isNaN(val) ? fallback : val;
}

const defaultConfig: LocalUserConfig = {
    video: {
        sliceFlg: true,
        sliceTime: 1000,
        useSliceM: false,
        mode: VideoToImageMode.MS,
        sliceM: 10,
        removeVideo: true,
    },
    aspect: {
        use: false,
        w: 4,
        h: 3,
    },
};
const defaultConfigString: string = JSON.stringify(defaultConfig);

const codec: LocalCodec<LocalUserConfig> = {
    parse(raw) {
        const config: LocalUserConfig = JSON.parse(defaultConfigString); // clone!
        let input: any = null;
        if (raw !== null) {
            try {
                input = JSON.parse(raw);
            } catch (err) {}
        }
        if (typeof input === 'object' && input !== null) {
            if (typeof input.aspect === 'object' && input.aspect !== null) {
                config.aspect.use = !!input.aspect.use;
                config.aspect.w = coerceInt(input.aspect.w, 0);
                config.aspect.h = coerceInt(input.aspect.h, 0);
            }
            if (typeof input.video === 'object' && input.video !== null) {
                config.video.sliceFlg = input.video.sliceFlg;
                config.video.sliceTime = coerceInt(input.video.sliceTime);
                config.video.useSliceM = !!input.video.useSliceM;
                config.video.sliceM = coerceInt(input.video.sliceM);
                config.video.removeVideo = !!input.video.removeVideo;
            }
        }
        return config;
    },
    stringify(input) {
        const newString = JSON.stringify(input);
        return newString === defaultConfigString ? null : newString;
    },
};

let prevConfig:
    | { userId: string; config: IComputedValue<LocalUserConfig> }
    | undefined;
export function getUserConfig(userId: string) {
    if (!prevConfig || prevConfig.userId !== userId) {
        prevConfig = {
            userId,
            config: createLocalUserConfig(userId),
        };
    }
    return prevConfig.config;
}

export function createLocalUserConfig(userId: string) {
    return createLocalBox(`${LOCAL_KEY_PREFIX}_${userId}`, codec);
}

export interface LocalAspectConfig {
    use: boolean;
    w: number;
    h: number;
}

export interface LocalVideoConfig {
    sliceFlg: boolean;
    mode: VideoToImageMode;
    sliceTime: number;
    useSliceM: boolean;
    sliceM: number;
    removeVideo: boolean;
}

export interface LocalUserConfig {
    video: LocalVideoConfig;
    aspect: LocalAspectConfig;
}
